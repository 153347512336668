function readyHandler(fn: () => void) {
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    setTimeout(fn, 1);
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

function maybeClickListener(elem: Element, fn: (evt: Event) => void) {
  if (elem) {
    elem.addEventListener("click", fn);
  }
}

function setAriaExpanded(elem: Element, value: boolean) {
  elem.setAttribute("aria-expanded", value ? "true" : "false");
}

function mainMenu() {
  let showMenu = false;
  const menuButton = document.querySelector(".mobile-toggle");
  const header = document.querySelector("header");

  const updateHeader = () => {
    if (header) {
      setAriaExpanded(menuButton, showMenu);
      if (showMenu) {
        header.classList.add("show-menu");
      } else {
        header.classList.remove("show-menu");
      }
    }
  };

  const toggleMenu = (evt: Event) => {
    evt.preventDefault();
    showMenu = !showMenu;
    updateHeader();
  };

  maybeClickListener(menuButton, toggleMenu);
}

export default function navigation() {
  readyHandler(() => {
    mainMenu();
  });
}
